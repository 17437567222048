import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  CardTitle,
  Table,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import ModalDetail from './Component/Class/ModalDetail';

//redux
import { useSelector, useDispatch } from "react-redux";
import { get } from "helpers/api_helper";
import Pagination from "./Component/Student/Pagination";
import LoadingSpinner from "components/Common/LoadingSpinner";

const Class = props => {
  const [modal, setModal] = useState(false);
  const [idClass, setIdClass] = useState(null);
  // Table Data
  const [dataClass, setDataClass] = useState([]);
  const [dataClassFiltered, setDataClassFiltered] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [ filter,setFilter ] = useState({key:''});
  const [ isLoading, setIsloading ] = useState(false);
  const [error_alert, seterror_alert] = useState(false);
  const [loadSync, setLoadSync] = useState(false);
  const [success_alert, setsuccess_alert] = useState(false);
  const [text_success_alert, settext_success_alert] = useState(false)

  const getData = async ()=> {
    setIsloading(true);
    try {
      let response = await get("/class/all");
      let data = response.data;
      const map = data.map((item, index) => {
        let id = item.id;
        let namaKelas = item.name;
        let grade = item.level_number;
        let waliKelas = item?.homeroom_teacher?.teacher?.full_name || "-";
        let jumlahSiswa = item.pickup.total_absen + '/' + item.total_siswa;
        let keyExtractor = `${namaKelas}-${grade}-${waliKelas}-${jumlahSiswa}`.toLowerCase();
        return { id: id, namaKelas: namaKelas, grade: grade, waliKelas: waliKelas, jumlahSiswa: jumlahSiswa, keyExtractor: keyExtractor };
      });
      const sortedData = map.sort((a,b)=>a.id - b.id);
      setDataClass(sortedData);
      setDataClassFiltered(sortedData);
    } catch (error) {
      seterror_alert(true);
    }
    setIsloading(false);
  }

  useEffect (()=>{
    getData();
  }, []);

  useEffect(()=>{
    setCurrentPage(1);
    const newData = dataClass.filter(item => {
      return ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true);
    })
    setDataClassFiltered(newData)
  },[filter]);

  const handlerToggle = (id = null) => {
    if(!modal) { setIdClass(id) }
    else { setIdClass(null) }
    setModal(!modal);
  }

  const syncData = async () => {
    setLoadSync(true)
    try {
      const response = await get('/tzuchidb/mergeclass');
      if (response) {
        setLoadSync(false)
        setsuccess_alert(true)
        settext_success_alert('Success merge data')
      }
    } catch (error) {
      setLoadSync(false)
      seterror_alert(true)
    }
  }

  const header = ["Class Name","Grade","Form Teacher","Number of Students","Action"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = dataClassFiltered.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Tzu Chi</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Master Data")}
              breadcrumbItem={props.t("Kelas")}
            />
            
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Kelas</CardTitle>
                    <p className="card-title-desc"></p>

                    <Row className="my-2">
                      <Col md="6" className="mt-2">
                        {loadSync ?
                          <Button
                            color="warning"
                            className="btn-sm m-1"
                            disabled
                          >
                            Sync Data <Spinner animation="border" className="mx-1" style={{width: '1rem', height: '1rem'}}>Loading...</Spinner>
                          </Button>
                        :
                          <Button
                            color="warning"
                            onClick={syncData}
                            className="btn-sm m-1"
                          >
                            Sync Data
                          </Button>
                        }
                      </Col>
                      <Col md="6" className="mt-2">
                        <div className="search-box me-2 mb-3 d-flex justify-content-end">
                          <div className="position-relative">
                            <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className='table-responsive'>
                      <Table className='table'>
                        <thead>
                            <tr>
                              {header.map((x, index) => (
                                <th key={`head-${index}`}>{x}</th>
                              ))}
                            </tr>
                        </thead>
                        <tbody>
                          {currentData.length > 0 ?
                            isLoading ? 
                              <tr>
                                  <td colSpan={5}><LoadingSpinner/></td>
                              </tr>
                            : 
                            currentData.map((e,index) => {
                                return(
                                  <React.Fragment key={`${e.id}-${index}`}>
                                    <tr>
                                      <td>{e?.namaKelas || ""}</td>
                                      <td>{e?.grade || ""}</td>
                                      <td>{e?.waliKelas || ""}</td>
                                      <td>{e?.jumlahSiswa || ""}</td>
                                      <td>
                                        <Button className="btn btn-sm btn-primary mx-1" onClick={() => handlerToggle(e.id)}>Detail</Button>  
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })
                            :
                            <tr>
                              <td colSpan={5} className="text-center">No Data</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      setDataPerPage={setDataPerPage}
                      dataPerPage={dataPerPage}
                      totalData={dataClassFiltered.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>

      <ModalDetail toggleModal={handlerToggle} modalShow={modal} idClass={idClass} />
      {success_alert && (
        <SweetAlert
          title="Success!"
          confirmBtnText="Yes"
          success
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setsuccess_alert(false)
          }}
        >
          {text_success_alert}
        </SweetAlert>
      )}
      { error_alert ?
          <SweetAlert
              title="Oppss!"
              confirmBtnText="Yes"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  seterror_alert(false)
              }}
          >
              Something wrong, please try again
          </SweetAlert>
      : null }
    </div>
  );
  ActionButton.propTypes = {
    id: PropTypes.number
  }
};


Class.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Class);
