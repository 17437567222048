import axios from "axios";
import { API_URL } from "./url_helper";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
// const API_URL = "https://demo-tzuchi-api.kirei.co.id";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.request.use((cfg)=>{
  const token = localStorage.getItem('accessToken')
  cfg.headers.Authorization = token
  // console.log(cfg);
  return cfg
})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    let responseCode;
    if(error.response?.status){
      responseCode = error.response.status
    }
    if(responseCode === 401){
      window.location.href = '/login'
    } 
    return Promise.reject(error)
  }
);

const axiosUploadFile = axios.create({
  baseURL: API_URL,
});

axiosUploadFile.interceptors.request.use((cfg)=>{
  cfg.headers.Authorization = token;
  cfg.headers.Accept = `multipart/form-data`;
  return cfg
})

axiosUploadFile.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data = {}, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

export async function postFile(url,data,config = {}){
  return await axiosUploadFile
    .post(url, {...data}, {...config})
    .then(response => response.data)
}
