import React from 'react';
import { Row, Spinner } from 'reactstrap';

export default function LoadingSpinner() {
  return (
    <Row className="text-center">
        <div>
            <Spinner color="primary" />
        </div>
    </Row>
    );
}
