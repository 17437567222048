import React, { useEffect, useState } from 'react'
import PropTypes, { array } from 'prop-types'
import { Card, CardBody, Col, Row } from 'reactstrap'

const TotalStudentCard = ({totalStudents,handleSearch}) => {
    const [summary,setSummary] = useState([])
    const [hoverStat, setHoverStat] = useState([])

    useEffect(() => {
        let data = [
        {
            title: "Total Student",
            total: totalStudents.total,
            iconClass: "bx-user",
            iconBgColor: "primary",
            query:""
        },
        {
            title: "Active Superfetcher",
            total: totalStudents.registered,
            iconClass: "bx-user-check",
            iconBgColor: "success",
            query:"active"
        },
        {
            title: "Pending Superfetcher",
            total: totalStudents.pending,
            iconClass: "bx-user",
            iconBgColor: "warning" ,
            query:"email sent"
        },
        {
            title: "Unregistered Superfetcher",
            total: totalStudents.unregistered,
            iconClass: "bx-user-x",
            iconBgColor: "danger" ,
            query:"unregistered"
        }]
        setSummary(data)

        const hoverArr = data.map(e => {
            return {title:e.title,isHover:false}
        })
        setHoverStat(hoverArr)
    },[totalStudents])

    const handleHover = (title,hover) => {
        const hoverState = hoverStat.map(e =>{
            if(e.title === title){
                return {...e,isHover:hover}
            } else {
                return e
            } 
        })
        setHoverStat(hoverState)
    }
  return (
    <Row>
        {
            summary.map((data,index) => {
                return(
                    <React.Fragment key={`_col_${data.title}`}>
                        <Col lg={3} sm={12}>
                            <Card onClick={()=> handleSearch(data.query)} className={hoverStat.filter(e => e.title === data.title)[0].isHover ? `opacity-50`:``} >
                                <CardBody onMouseEnter={()=> handleHover(data.title,true)} onMouseLeave={()=> handleHover(data.title,false)}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {data.title}
                                            </p>
                                            <h4 className="mb-0">{data.total}</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className={`avatar-title rounded-circle bg-${data?.iconBgColor || "primary"}`}>
                                                <i
                                                className={
                                                    "bx " + data.iconClass + " font-size-24"
                                                }
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </React.Fragment>
                )
            })
        }
    </Row>
  )
}

TotalStudentCard.propTypes = {
    totalStudents: PropTypes.object.isRequired,
    handleSearch: PropTypes.func.isRequired
  };

export default TotalStudentCard