import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  Label,
  Input
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { postFile } from "helpers/api_helper";
import { API_URL } from 'helpers/url_helper';

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

const ModalAddData = (props) => {
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [message_alert, setmessage_alert] = useState(false);
    const [inputErr, setInputErr] = useState({text: false, alert: false});
    const [dataFile, setDataFile] = useState();

    const handlerSubmit = () => {
        if(dataFile == undefined) { setInputErr({text: true, alert: true}) }
        else { 
            setInputErr({text: false, alert: false});
            setconfirm_alert(true);
        }
    }

    const postFile = async () => {
        let formData = new FormData(); 
        formData.append('file', dataFile); 

        const token = localStorage.getItem('accessToken');

        const config = {
            headers: {
                Authorization: token,
                'content-type': 'multipart/form-data'
            }
        }

        let response = await axios.post(`${API_URL}/user/import_fetcher_data_raw`, formData, config);
        if(response) {
            setmessage_alert(true);
        }
    }

    useEffect(() => {
        if(!props.modalShow) { setDataFile() }
    }, [props.modalShow])
   
    return(
        <div>
            <Modal
                size="lg"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
                scrollable={true}
            >
                <ModalHeader toggle={props.toggleModal}>Add New Super Fetcher Data</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <p className="mb-1">Follow this step to add new Super Fetcher :</p>
                            <li className="mb-0 text-muted">Download template file format <a href={API_URL + '/public/exceldata/superfetcher_format.xlsx'}>Click Here</a></li>
                            <li className="mb-0 text-muted">Entry the new Super Fetcher data according to the template format</li>
                            <li className="mb-0 text-muted">Upload the file by clicking the button below</li>
                            <li className="mb-0 text-muted">Wait until the upload process finished, then click submit to add the Super Fetcher</li>
                            <div className="row mb-3">
                                <Col sm={12}>
                                    <div className="mt-3">
                                        <Label htmlFor="formFile" className="form-label">Submit File</Label>
                                        <Input className="form-control" type="file" id="formFile" onChange={(e) => setDataFile(e.target.files[0])} files={dataFile} />
                                        { inputErr.text ?
                                            <span className="text-danger">This field is required, please insert a file</span>
                                        : null }
                                    </div>
                                </Col>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <div className="d-flex flex-row justify-content-end">
                                        <button className="btn btn-sm btn-primary mx-1" onClick={handlerSubmit}>Submit</button>
                                        <button className="btn btn-sm btn-danger" onClick={props.toggleModal}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            
            { confirm_alert ? 
                <SweetAlert
                    title= 'Warning!'
                    info
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {      
                        postFile();
                        setconfirm_alert(false);
                    }}
                    onCancel={() => {setconfirm_alert(false);}}
                >
                    Are you sure post this file
                </SweetAlert>
            : null }

            { message_alert ?
                <SweetAlert
                    title="Success!"
                    confirmBtnText="Yes"
                    success
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setmessage_alert(false);
                        props.toggleModal();
                    }}
                >
                    Success to post file
                </SweetAlert>
            : null }

            { inputErr.alert ?
                <SweetAlert
                    title="Upsss!"
                    confirmBtnText="Yes"
                    danger
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setInputErr({...inputErr, alert: false});
                    }}
                >
                    Please insert a file first
                </SweetAlert>
            : null }
        </div>
    )
}

ModalAddData.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
};

export default withTranslation()(ModalAddData);