import PropTypes, { array } from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Form,
  Spinner,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { del, get } from "helpers/api_helper"
import LoadingSpinner from "components/Common/LoadingSpinner"
import NoData from "components/Common/NoData"
import StudentTable from "./Component/Student/StudentTable"
import ModalAddData from "./Component/Student/ModalAddData"
import TotalStudentCard from "./Component/Student/TotalStudentCard"

const Student = props => {
  const [isExpandLoading, setIsExpandLoading] = useState(false)
  const [fetch, setFetch] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [students, setStudents] = useState([])
  const [totalStudents, setTotalStudents] = useState({
    total: 0,
    registered: 0,
    unregistered: 0,
    pending: 0,
  })
  // Table Data
  const [studentData, setStudentData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [dataExpand, setDataExpand] = useState({})
  const [modal, setModal] = useState(false)
  const [success_alert, setsuccess_alert] = useState(false)
  const [text_success_alert, settext_success_alert] = useState('')
  const [error_alert, seterror_alert] = useState(false);
  const [loadSync, setLoadSync] = useState(false)
  const [fetcherModal, setFetcherModal] = useState(false)
  const [isConfirmDeleteShow, setIsConfirmDeleteShow] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const getExpandData = async (family_id, va) => {
    setIsExpandLoading(true)
    try {
      if (va != null) {
        const response = await get(`/student/get/data?va=${va}`)
        const { data } = response
        const studentData = data.student.find(student => student.va === va)
        const activeFetcher = data.fetcher.filter(e => !e.is_blocked).map(fetcher => ({
          ...fetcher,
          avatarErr: false,
        }))
        const inactiveDailyFetcher = data.fetcher.filter(e => e.is_blocked)
        const fetcherGuest = data.fetcher.filter(e => e.is_guest && !e.is_blocked).map(guest => ({
          ...guest,
          avatarErr: true
        }))
        const dataSibling = data.student
          .filter(e => e.va !== va)
          .map(sibling => ({ ...sibling, avatarErr: false }))
        let dataFetcherRaw = null
        if(data.fetcher_raw){
          dataFetcherRaw = {...data.fetcher_raw}
        }
        const expandData = {
          student: studentData.photo,
          avatarErr: false,
          activeFetcher,
          inactiveDailyFetcher,
          dataFetcherRaw,
          teacher: studentData.homeroom_teachers,
          sibling: dataSibling,
          guest: fetcherGuest
        }
        // console.log(expandData);
        setDataExpand(expandData)
      }
    } catch (error) {
      console.error(error)
      seterror_alert(true)
    } finally {
      setIsExpandLoading(false)
    }
  }

  const getDataExpand = async (family_id, va) => {
    setIsExpandLoading(true)
    setDataExpand({})

    var avatar = null
    var teacher = null
    var dataFetcherRaw = undefined
    var dataSibling = undefined
    var dataFether = undefined
    if (family_id != null) {
      try {
        let response = await get(`/student/get/data?family_id=${family_id}`)
        let data = response.data

        const activeFetcher = data.fetcher.filter(e => !e.is_blocked)
        dataFether = activeFetcher.map(e => {
          return {
            id: e.id,
            username: e.username,
            full_name: e.full_name,
            relationship: e.relationship,
            phone: e.phone,
            photo_profile: e.photo_profile,
            avatarErr: false,
            role: e?.role,
            is_guest: e?.is_guest,
          }
        })

        const sibling = data.student.filter(x => x.va != va)
        dataSibling = sibling.map(e => {
          return {
            id: e.id,
            name: e.name,
            kelas: e.kelas,
            va: e.va,
            photo: e.photo,
            avatarErr: false,
          }
        })

        const dataStudent = data.student.find(e => e.va == va)
        teacher = dataStudent.homeroom_teachers
        avatar = dataStudent.photo

        if (data.fetcher_raw !== null) {
          dataFetcherRaw = {
            id: data.fetcher_raw.id,
            name: data.fetcher_raw.full_name,
            phone: data.fetcher_raw.phone,
            relationship: data.fetcher_raw.relationship,
            email: data.fetcher_raw.email,
            url: data.fetcher_raw.url,
          }
        }
      } catch (error) {
        seterror_alert(true)
      }
    }
    let detail = {
      student: avatar,
      avatarErr: false,
      activeFetcher: dataFether,
      dataFetcherRaw: dataFetcherRaw,
      // kelas: kelas,
      teacher: teacher,
      sibling: dataSibling,
    }
    // console.log(detail)
    setDataExpand(detail)
    setIsExpandLoading(false)
  }

  const handleExpand = id => {
    const newData = studentData.map(e => {
      if (e.id === id) {
        e.isExpand = !e.isExpand
        // getDataExpand(e.family_id, e.va)
        getExpandData(e.family_id, e.va)
      } else {
        e.isExpand = false
      }
      return e
    })
    setStudentData(newData)
  }

  const handleSearch = entry => {
    setCurrentPage(1)
    const rawData = studentData
    const result = rawData.filter(e =>
      e.keyExtractor.includes(entry.toLowerCase())
    )
    setFilteredData(result)
  }

  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const getData = async () => {
    setFetch(true)
    try {
      const totalData = await get("/student/?cek_data=true")
      const response = await get("/student/")
      let data = response.data
      setTotalStudents({
        ...totalStudents,
        total: data.length,
        registered: totalData.data.registered_fetcher.total,
        unregistered: totalData.data.nofetcher.total,
        pending: totalData.data.unregistered_fetcher.total,
      })
      const map = data.map((item, index) => {
        let family_id = item.family_id
        let id = item.id
        let va = item.va
        let namaSiswa = item.name
        let grade = item?.kelas?.split(" ")[0] || "-"
        let kelas = item?.kelas?.split(" ")[1] || "-"
        let fetcher = (item?.fetcher || []).map(e => e.full_name).join("-")
        const result = {
          id: id,
          va: va,
          family_id: family_id,
          fetcher: fetcher,
          namaSiswa: namaSiswa,
          grade: grade,
          kelas: kelas,
          isExpand: false,
          keyExtractor: `${va}-${namaSiswa}-${grade}-${kelas}-${
            fetcher.length === 0 && family_id ? "email sent" : fetcher
          }-${family_id && fetcher.length !== 0 ? "active" : null}${
            !family_id ? "unregistered" : null
          }`.toLowerCase(),
        }
        return result
      })
      setStudents(map)

      const sortedData = map.sort((a, b) => a.va - b.va)
      const paramsVa = query.get("va")
      const paramsExpand = query.get("expand")
      if (paramsVa) {
        let filteredUser = sortedData.filter(x => x.va == paramsVa)
        if (paramsExpand == "true") {
          filteredUser = filteredUser.map(item => {
            item.isExpand = true
            // getDataExpand(item.family_id, item.va)
            getExpandData(item.family_id, item.va)
            return item
          })
        }
        setStudentData(filteredUser || [])
        setFilteredData(filteredUser)
      } else {
        setStudentData(sortedData)
        setFilteredData(sortedData)
      }
    } catch (error) {
      seterror_alert(true)
    }
    setFetch(false)
  }

  const syncData = async () => {
    setLoadSync(true)
    try {
      const response = await get('/tzuchidb/mergedb');
      if (response) {
        setLoadSync(false)
        setsuccess_alert(true)
        settext_success_alert('Success merge data')
      }
    } catch (error) {
      seterror_alert(true)
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await del('/user/'+id);
      if (response) {
        setsuccess_alert(true)
        settext_success_alert('Fetcher has been deleted')
        setIsConfirmDeleteShow(false)
        setFetcherModal(false)
        getData()
      }
    } catch (error) {
      seterror_alert(true)
      setIsConfirmDeleteShow(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const Header = ["VA", "Student Name", "Grade", "Class", "SF Status", "Action"]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master Data")}
            breadcrumbItem={props.t("Student & Fetcher")}
          />
          <Row>
            <Col md={12}>
              <TotalStudentCard
                totalStudents={totalStudents}
                handleSearch={handleSearch}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Student & Fetcher</CardTitle>
                  <p className="card-title-desc"></p>
                  <Row className="mb-2">
                    <Col md="6">
                      <div className="search-box me-2 mb-3 d-flex justify-content-start">
                        {loadSync ?
                          <Button
                            color="warning"
                            className="btn-sm m-1"
                            disabled
                          >
                            Sync Data <Spinner animation="border" className="mx-1" style={{width: '1rem', height: '1rem'}}>Loading...</Spinner>
                          </Button>
                        :
                          <Button
                            color="warning"
                            onClick={syncData}
                            className="btn-sm m-1"
                          >
                            Sync Data
                          </Button>
                        }
                        <Button
                          color="primary"
                          onClick={toggleModal}
                          className="btn-sm m-1"
                        >
                          Add Super Fetcher
                        </Button>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="search-box me-2 mb-3 d-flex justify-content-end">
                        <div className="position-relative ">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={e => handleSearch(e.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <StudentTable
                    data={filteredData}
                    header={Header}
                    handleExpand={handleExpand}
                    dataExpand={dataExpand}
                    setDataExpand={setDataExpand}
                    isLoading={fetch}
                    isExpandLoading={isExpandLoading}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    fetcherModal={fetcherModal}
                    setFetcherModal={setFetcherModal}
                    isConfirmDeleteShow={isConfirmDeleteShow}
                    setIsConfirmDeleteShow={setIsConfirmDeleteShow}
                    handleDelete={handleDelete}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalAddData modalShow={modal} toggleModal={toggleModal} />
      {success_alert && (
        <SweetAlert
          title="Success!"
          confirmBtnText="Yes"
          success
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setsuccess_alert(false)
          }}
        >
          {text_success_alert}
        </SweetAlert>
      )}
      {error_alert ? (
        <SweetAlert
          title="Oppss!"
          confirmBtnText="Yes"
          error
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            seterror_alert(false)
          }}
        >
          Something wrong, please try again
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

Student.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Student)
