import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  CardTitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner,
} from "reactstrap";
import LoadingSpinner from "components/Common/LoadingSpinner";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { get } from "helpers/api_helper";
import Pagination from "./Component/Student/Pagination";

const Teacher = props => {
  // Table Data
  const [ teacherData, setTeacherData ] = useState([]);
  const [ teacherDataFilter, setTeacherDataFilter ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [ filter,setFilter ] = useState({key:''});
  const [ isLoading, setIsloading ] = useState(false);
  const [error_alert, seterror_alert] = useState(false);
  const [loadSync, setLoadSync] = useState(false);
  const [success_alert, setsuccess_alert] = useState(false);
  const [text_success_alert, settext_success_alert] = useState(false)

  const getData = async ()=> {
    setIsloading(true);
    try {
      let response = await get("/teacher");
      let data = response.data;
      const map = data.map((item, index) => {
        let id = item.id;
        let namaGuru = item?.full_name || "-";
        let kelas = '-';
        let status = "Partner";
        if(item.main_class !== null) {
          kelas = item.main_class.class.name;
          status = "Form Teacher";
        }
        let keyExtractor = `${namaGuru}-${kelas}-${item.email}-${status}`.toLowerCase();
        return { id: id, namaGuru: namaGuru, kelas: kelas,email:item.email, status: status, keyExtractor: keyExtractor };
      });
      const sortedData = map.sort((a,b)=>a.id - b.id);
      setTeacherData(sortedData);
      setTeacherDataFilter(sortedData);
    } catch (error) {
      seterror_alert(true);
    }
    setIsloading(false);
  }

  const syncData = async () => {
    setLoadSync(true)
    try {
      const response = await get('/tzuchidb/mergeteacher_assistant');
      if (response) {
        setLoadSync(false)
        setsuccess_alert(true)
        settext_success_alert('Success merge data')
      }
    } catch (error) {
      setLoadSync(false)
      seterror_alert(true)
    }
  }

  useEffect (()=>{
    getData();
  }, []);

  useEffect(()=>{
    setCurrentPage(1);
    const newData = teacherData.filter(item => {
      return ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true);
    })
    setTeacherDataFilter(newData)
  },[filter]);

  const header = ["Teacher Name","Email","Class","Status"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = teacherDataFilter.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master Data")}
            breadcrumbItem={props.t("Teacher")}
          />
          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Teacher</CardTitle>
                  <p className="card-title-desc"></p>

                  <Row className="my-2">
                    <Col md="6" className="mt-2">
                      {loadSync ?
                        <Button
                          color="warning"
                          className="btn-sm m-1"
                          disabled
                        >
                          Sync Data <Spinner animation="border" className="mx-1" style={{width: '1rem', height: '1rem'}}>Loading...</Spinner>
                        </Button>
                      :
                        <Button
                          color="warning"
                          onClick={syncData}
                          className="btn-sm m-1"
                        >
                          Sync Data
                        </Button>
                      }
                    </Col>
                    <Col md="6" className="mt-2">
                      <div className="search-box me-2 mb-3 d-flex justify-content-end">
                        <div className="position-relative ">
                          <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className='table-responsive'>
                    <Table className='table'>
                      <thead>
                          <tr>
                            {header.map((x, index) => (
                              <th key={`head-${index}`}>{x}</th>
                            ))}
                          </tr>
                      </thead>
                      <tbody>
                        {currentData.length > 0 ?
                          isLoading ? 
                            <tr>
                                <td colSpan={4}><LoadingSpinner/></td>
                            </tr>
                          : 
                          currentData.map((e,index) => {
                              return(
                                <React.Fragment key={`${e.id}-${index}`}>
                                  <tr>
                                    <td>{e?.namaGuru || "N/A"}</td>
                                    <td>{e?.email || "N/A"}</td>
                                    <td>{e?.kelas || "N/A"}</td>
                                    <td><Badge className={e.status == 'Partner' ? 'bg-secondary py-1' : 'bg-success py-1'}>{e.status}</Badge></td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                          :
                          <tr>
                            <td colSpan={4} className="text-center">No Data</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    setDataPerPage={setDataPerPage}
                    dataPerPage={dataPerPage}
                    totalData={teacherDataFilter.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {success_alert && (
        <SweetAlert
          title="Success!"
          confirmBtnText="Yes"
          success
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setsuccess_alert(false)
          }}
        >
          {text_success_alert}
        </SweetAlert>
      )}
      { error_alert ?
          <SweetAlert
              title="Oppss!"
              confirmBtnText="Yes"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  seterror_alert(false)
              }}
          >
              Something wrong, please try again
          </SweetAlert>
      : null }
    </React.Fragment>
  );
  ActionButton.propTypes = {
    id: PropTypes.number
  }
};


Teacher.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Teacher);
