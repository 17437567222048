import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row, Table } from "reactstrap"
import user1 from "../../../../assets/images/users/avatar-blank.png"
import { API_URL } from "helpers/url_helper"
import { Link } from "react-router-dom"
import { post } from "helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import StringUtils from "utils/String"
import ModalEditSuperFetcher from "./ModalEditSuperFetcher"

const StudentDetail = ({ data, dataExpand, setDataExpand, handleExpand, toggleInactiveDailyFetcherModal }) => {
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [message_alert, setmessage_alert] = useState(false)
  const [text_message_alert, settext_message_alert] = useState("")
  const [copyLink, setCopyLink] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [fetcherModal, setFetcherModal] = useState(false)
  const [dataFetcher, setDataFetcher] = useState([]);
  const [lengthFetcher, setLengthFetcher] = useState(0);
  
  const [body, setBody] = useState({
    user_id: "",
    family_id: "",
    role_id: 5,
    va: "",
    full_name: "",
    email: "",
    phone: "",
    relation: "",
  })

  const handlerAvatarErr = (item, looping = null) => {
    if (item == "fetcher") {
      const newData = dataExpand.activeFetcher.map((e, index) => {
        if (looping == index) {
          return {
            id: e.id,
            username: e.username,
            full_name: e.full_name,
            relationship: e.relationship,
            phone: e.phone,
            photo_profile: e.photo_profile,
            avatarErr: true,
            role: e.role,
          }
        } else {
          return e
        }
      })
      const sortedNewData = [].concat(
        newData.filter(e => e.role.id >= 5),
        newData.filter(e => e.role.id < 5)
      )
      setDataExpand({ ...dataExpand, activeFetcher: sortedNewData })
    } else if (item == "sibling") {
      const newData = dataExpand.sibling.map((e, index) => {
        if (looping == index) {
          return {
            id: e.id,
            name: e.name,
            kelas: e.kelas,
            va: e.va,
            photo: e.photo,
            avatarErr: true,
          }
        } else {
          return e
        }
      })
      setDataExpand({ ...dataExpand, sibling: newData })
    } else if (item == "student") {
      setDataExpand({ ...dataExpand, avatarErr: true })
    }
  }

  const handlerSendLink = async email => {
    const body = {
      email: email,
    }
    try {
      let response = await post(`/user/reactivate_registration_link`, body)
      if (response) {
        setmessage_alert(true)
        settext_message_alert("Success to resend link registration")
      }
    } catch (error) {
      seterror_alert(true)
    }
  }

  const handlerCopy = () => {
    setCopyLink(true)
    navigator.clipboard.writeText(dataExpand.dataFetcherRaw.url)
  }

  const detailFetcherRaw = (data, type) => {
    return (
      <>
        <div className="mb-3" key={data.id}>
          <Row>
            {type === "fetcher_raw" ? 
              <>
                <Col md="4">
                  <img
                    src={user1}
                    className="avatar-lg img-fluid rounded"
                    style={{ objectFit: "cover" }}
                  />
                </Col>
                <Col md="8" className="">
                  <table className="table table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td className="card-title-desc">Name</td>
                        <td>{data?.full_name || "-"}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">Relation</td>
                        <td>{data?.relationship || "-"}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">Phone</td>
                        <td>{data?.phone || "-"}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">Link Registration</td>
                        <td>
                          <button
                            className={`btn btn-sm btn-${
                              copyLink ? "success" : "info"
                            }`}
                            onClick={handlerCopy}
                            disabled={copyLink}
                          >
                            {copyLink ? <i className="bx bx-check" /> : null} Copy
                            Link
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="card-title-desc"></td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => setconfirm_alert(true)}
                          >
                            Resend Registration Link
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </>
            :
              <Col md="11" className="offset-md-1">
                <table className="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td className="card-title-desc">Name</td>
                      <td>{data?.full_name || "-"}</td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">Relation</td>
                      <td>{data?.relationship || "-"}</td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">Phone</td>
                      <td>{data?.phone || "-"}</td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">Link Registration</td>
                      <td>
                        <button
                          className={`btn btn-sm btn-${
                            copyLink ? "success" : "info"
                          }`}
                          onClick={handlerCopy}
                          disabled={copyLink}
                        >
                          {copyLink ? <i className="bx bx-check" /> : null} Copy
                          Link
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="card-title-desc"></td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => setconfirm_alert(true)}
                        >
                          Resend Registration Link
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            }
          </Row>
        </div>
      </>
    )
  }

  const detailRender = (data, index) => {
    return (
      <div className="mb-3" key={data.id}>
        <Row>
          <Col md="4">
            <img
              src={
                data.avatarErr
                  ? user1
                  : `${API_URL + `/` + data?.photo_profile || ""}`
              }
              onError={() => handlerAvatarErr("fetcher", index)}
              className="avatar-lg img-fluid rounded"
              style={{ objectFit: "cover" }}
            />
          </Col>
          <Col md="8">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td className="card-title-desc">Name</td>
                  <td>{data.full_name}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Role</td>
                  <td>
                    <b>
                      {data.is_guest
                        ? "Guest Fetcher"
                        : StringUtils.FirstLetterUpperCase(
                            data?.role?.description || "Super Fetcher"
                          )}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td className="card-title-desc">Relation</td>
                  <td>{data?.relationship}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Phone</td>
                  <td>{data?.phone || "-"}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Username</td>
                  <td>
                    <Link to={`/user?username=${data.username}`}>
                      {" "}
                      {data?.username || "-"}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    )
  }

  const detailSibling = (data, index) => {
    return (
      <div className="mb-3" key={data.id}>
        <Row>
          <Col md="4">
            <img
              src={
                data.avatarErr ? user1 : `${API_URL + `/` + data?.photo || ""}`
              }
              onError={() => handlerAvatarErr("sibling", index)}
              className="avatar-lg img-fluid rounded"
              style={{ objectFit: "cover" }}
            />
          </Col>
          <Col md="8" className="my-detail">
            <h6>Sibling Detail</h6>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td className="card-title-desc">Name</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">VA</td>
                  <td>{data.va}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Class</td>
                  <td>{data.kelas}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    )
  }

  const handleEditSuperFetcher = (data) => {
    setLengthFetcher(data.length)
    setDataFetcher(data)
    if (!fetcherModal) {
      setFetcherModal(true)
    } else {
      setFetcherModal(false)
    }
  }

  const handleChange = (e) => {
    if ('target' in e) {
      const { name, value } = e.target
        setBody({
            ...body,
            [name]: value,
            va: data.va
        })
    } else {
      setBody({
          ...body,
          user_id: e.value,
          family_id: data.family_id
      })
    }
  }

  const submitFetcher = async (url, json, text) => {
    try {
      const response = await post(url, json)
      if (response) {
        setmessage_alert(true)
        settext_message_alert(text)
      }
    } catch (error) {
      seterror_alert(true)
    }
  }

  const handleSubmit = async () => {
    let json = {};
    if ("full_name" in body && body.full_name !== "") {
      let url = '/user/create_fetcher_data_raw'
      json = {
        full_name: body.full_name,
        email: body.email,
        phone: body.phone,
        role_id: body.role_id,
        relationship: body.relation,
        va: body.va
      }
      submitFetcher(url, json, "Success to add fetcher") 
    } else {
      let url = '/user/change_superfetcher'
      json = {
        user_id: body.user_id,
        email: body.email,
        family_id: body.family_id
      }
      submitFetcher(url, json, "Success to edit fetcher") 
    }
  }

  return (
    <>
      <div className="bg-light rounded p-5">
        <Row>
          <Col md="6" className="">
            <Row>
              <Col md="4">
                <img
                  src={
                    dataExpand?.avatarErr
                      ? user1
                      : `${API_URL + `/` + dataExpand?.student || ""}`
                  }
                  onError={() => handlerAvatarErr("student")}
                  className="avatar-lg img-fluid rounded"
                  style={{ objectFit: "cover" }}
                />
              </Col>
              <Col md="8" className="my-detail">
                <h6>Student Detail</h6>
                <Table className="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td className="card-title-desc">Name</td>
                      <td>{data.namaSiswa}</td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">VA</td>
                      <td>{data.va}</td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">Class</td>
                      <td>
                        {data.grade} {data.kelas}
                      </td>
                    </tr>
                    <tr>
                      <td className="card-title-desc">HR. Teacher</td>
                      <td>{dataExpand.teacher}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <h6>Siblings</h6>
              {dataExpand.sibling == undefined ? (
                <p className="text-muted">No Sibling</p>
              ) : dataExpand.sibling.length === 0 ? (
                <p className="text-muted">No Sibling</p>
              ) : (
                dataExpand.sibling.map((x, index) => detailSibling(x, index))
              )}
            </Row>
          </Col>
          <Col md="6" className="">
            <Row>
              <Col>
                <h6>Fetcher Detail </h6>
              </Col>
              <Col className="text-right mb-2">
                  <Button className="btn btn-success btn-sm" onClick={() => handleEditSuperFetcher(dataExpand.activeFetcher)}>Edit Super Fetcher</Button>
              </Col>
            </Row>
            {dataExpand.activeFetcher == undefined &&
            dataExpand.dataFetcherRaw == undefined ? (
              <p className="text-muted">No Active Fetcher</p>
            ) : dataExpand.activeFetcher.length > 0 ? (
              <>
                {dataExpand.activeFetcher.map((a, index) => detailRender(a, index))}
                {dataExpand.dataFetcherRaw != undefined && (
                  <div className="mt-2">
                    {detailFetcherRaw(dataExpand.dataFetcherRaw, 'fetcher_raw')} 
                  </div>
                )}
              </>
            ) : dataExpand.dataFetcherRaw != undefined ? (
                detailFetcherRaw(dataExpand.dataFetcherRaw, "")
            ) : (
              <p className="text-muted">No Active Fetcher</p>
            )}
            <Row className="pb-3">
              <Col>
                <h6>Guest Detail</h6>
                <div className="py-2">
                  {dataExpand.guest !== undefined && dataExpand.guest.length > 0 ?
                    dataExpand.guest.map((item, key) => 
                        detailRender(item, key)
                    )
                  :
                    <p className="text-center">No Data Guest</p>
                  }
                </div>
              </Col>
            </Row>
            {dataExpand.inactiveDailyFetcher.length > 0 && (
              <div className="row">
                <Button className="btn btn-block" onClick={toggleInactiveDailyFetcherModal}>See Inactive Daily Fetcher List</Button>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <ModalEditSuperFetcher
        modalShow={fetcherModal}
        toggleModal={handleEditSuperFetcher}
        data={dataFetcher}
        length={lengthFetcher}
        body={body}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />

      {confirm_alert ? (
        <SweetAlert
          title="Warning!"
          info
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            handlerSendLink(dataExpand.dataFetcherRaw.email)
          }}
          onCancel={() => {
            setconfirm_alert(false)
          }}
        >
          Are you sure to resend link registration to this fetcher
        </SweetAlert>
      ) : null}

      {message_alert ? (
        <SweetAlert
          title="Success!"
          confirmBtnText="Yes"
          success
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            handleExpand(data.id)
            setmessage_alert(false)
          }}
        >
          {text_message_alert}
        </SweetAlert>
      ) : null}

      {error_alert ? (
        <SweetAlert
          title="Oppss!"
          confirmBtnText="Yes"
          error
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            seterror_alert(false)
          }}
        >
          Something wrong, please try again
        </SweetAlert>
      ) : null}
    </>
  )
}

StudentDetail.propTypes = {
  data: PropTypes.any,
  dataExpand: PropTypes.any,
  setDataExpand: PropTypes.any,
  handleExpand: PropTypes.any,
  toggleInactiveDailyFetcherModal: PropTypes.any
}

export default StudentDetail
