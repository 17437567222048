import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from "reactstrap"

import user1 from "../../../../assets/images/users/avatar-blank.png"
import { API_URL } from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { put, del } from "helpers/api_helper"

const ModalReactivateFetcher = ({ modalShow, dataExpand, toggleModal, isConfirmDeleteShow, setIsConfirmDeleteShow, handleDelete }) => {
  const [isReactivateConfirmShow, setIsReactivateConfirmShow] = useState(false)
  const [alert, setAlert] = useState({
    type: "",
    alert: false,
    title: "",
    message: "",
  })
  const [selectedFetcher, setSelectedFetcher] = useState(null)
  const [userId, setUserId] = useState('');
  const { inactiveDailyFetcher } = dataExpand

  const handleReactivate = fetcher => {
    setSelectedFetcher(fetcher)
    setIsReactivateConfirmShow(true)
  }

  const handleSubmit = async () => {
    try {
      const res = await put(`/user/active/fetcher/${selectedFetcher.id}`, null)
      setAlert({
        type: "success",
        alert: true,
        title: "Done!",
        message: "Fetcher has been reactivated",
      })
    } catch (error) {
      setAlert({
        type: "error",
        alert: true,
        title: "Oopps!",
        message: error.response.data.message,
      })
    } finally {
      setIsReactivateConfirmShow(false)
    }
  }
  return (
    <Modal isOpen={modalShow} centered>
      <ModalHeader toggle={() => toggleModal()}>
        Inactive Daily Fetcher
      </ModalHeader>
      <ModalBody>
        <table className="table">
          <thead>
            <tr>
              <th>Nama</th>
              <th>No HP</th>
              <th>Avatar</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {inactiveDailyFetcher &&
              inactiveDailyFetcher.map(fetcher => {
                return (
                  <tr key={fetcher.id}>
                    <td>{fetcher.full_name || ""}</td>
                    <td>{fetcher.phone || ""}</td>
                    <td>
                      <Avatar photo={fetcher.photo_profile} />
                    </td>
                    <td>
                      <Button
                        color="success"
                        className="btn-sm m-1"
                        onClick={() => handleReactivate(fetcher)}
                      >
                        Reactivate
                      </Button>
                      <Button
                        color="danger"
                        className="btn-sm m-1"
                        onClick={() => {setIsConfirmDeleteShow(true); setUserId(fetcher.id)}}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </ModalBody>
      {isReactivateConfirmShow && (
        <SweetAlert
          title="Daily fetcher Reactivation"
          type="warning"
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="secondary"
          showCancel
          onCancel={() => setIsReactivateConfirmShow(false)}
          onConfirm={() => handleSubmit()}
        >
          Press Confirm to give selected Fetcher Access
        </SweetAlert>
      )}
      {isConfirmDeleteShow && (
        <SweetAlert
          title="Are you sure"
          type="warning"
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="secondary"
          showCancel
          onCancel={() => setIsConfirmDeleteShow(false)}
          onConfirm={() => handleDelete(userId)}
        >
          {"You won't be able to revert this!"}
        </SweetAlert>
      )}
      {alert.alert && (
        <SweetAlert
          title={alert.title}
          type={alert.type}
          onConfirm={() => {
            setAlert({ ...alert, alert: false })
          }}
        >
          {alert.message}
        </SweetAlert>
      )}
    </Modal>
  )
}

const Avatar = props => {
  const [avatarErr, setAvatarErr] = useState(false)
  return (
    <img
      src={avatarErr ? user1 : `${API_URL + `/` + props.photo}`}
      onError={() => setAvatarErr(true)}
      className="avatar-sm img-fluid rounded"
      style={{ objectFit: "cover" }}
    />
  )
}
Avatar.propTypes = {
  photo: PropTypes.any,
}

ModalReactivateFetcher.propTypes = {
  dataExpand: PropTypes.any,
  toggleModal: PropTypes.any,
  modalShow: PropTypes.bool,
  isConfirmDeleteShow: PropTypes.any,
  setIsConfirmDeleteShow: PropTypes.any,
  handleDelete: PropTypes.any
}

export default ModalReactivateFetcher
