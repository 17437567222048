import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const ModalAdd = (props) => {
    const [inputFullName, setInputFullName] = useState('');
    const [inputName, setInputName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputConfirmPassword, setInputConfirmPassword] = useState('');
    const [isPasswordSame, setIsPasswordSame] = useState(true);
    const HandlerInputFullName = (e) => {
        setInputFullName(e.target.value);
    };
    const HandlerInputName = (e) => {
        setInputName(e.target.value);
    };
    const HandlerInputEmail = (e) => {
        setInputEmail(e.target.value);
    };
    const HandlerInputPhone = (e) => {
        setInputPhone(e.target.value);
    };
    const HandlerInputPassword = (e) => {
        setInputPassword(e.target.value);
        if(e.target.value != '' && inputPassword != e.target.value) {
            setIsPasswordSame(false);
        }
        else {
            setIsPasswordSame(true);
        }
    };
    const HandlerInputConfirmPassword = (e) => {
        setInputConfirmPassword(e.target.value);
        if(e.target.value != '' && inputPassword != e.target.value) {
            setIsPasswordSame(false);
        }
        else {
            setIsPasswordSame(true);
        }
    };
    
    const uploadDataUser = () => {}

    return(
        <Modal
            size="lg"
            centered={true}
            isOpen={props.modalShow}
            toggle={props.toggleModal}
        >
            <ModalHeader toggle={props.toggleModal}>Add User</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                            <Label for="full_name">Full Name</Label>
                            <Input id="full_name" name="full_name" type="text" onChange={HandlerInputFullName} value={inputFullName} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <Label for="username">Username</Label>
                            <Input id="username" name="username" type="text" onChange={HandlerInputName} value={inputName} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                            <Label for="email">Email</Label>
                            <Input id="email" name="email" type="email" onChange={HandlerInputEmail} value={inputEmail} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <Label for="phone">No Telp</Label>
                            <Input id="phone" name="phone" type="number" onChange={HandlerInputPhone} value={inputPhone} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                            <Label for="password">Password</Label>
                            <Input id="password" name="password" type="password" onChange={HandlerInputPassword} value={inputPassword} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <Label for="confirm_password">Confirm Password</Label>
                            <Input id="confirm_password" name="confirm_password" type="password" onChange={HandlerInputConfirmPassword} value={inputConfirmPassword} />
                            {isPasswordSame ? null : <Label className="text-danger">Password Tidak Sama</Label> }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="d-flex flex-row-reverse">
                            <Button className="btn-sm" color="danger" onClick={props.toggleModal}>
                                Cancel
                            </Button>
                            <Button className="btn-sm mx-1" color="primary" onClick={uploadDataUser}>
                                Simpan
                            </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

ModalAdd.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any
};

export default withTranslation()(ModalAdd);