import React, { useState } from "react"
import PropTypes, { array } from "prop-types"
import { Button, Table, Row, Col, Badge } from "reactstrap"
import StudentDetail from "./StudentDetail"
import LoadingSpinner from "components/Common/LoadingSpinner"
import Pagination from "./Pagination"
import { propTypes } from "react-bootstrap-editable"
import ModalEditFamily from "./modalEditFamily"
import SweetAlert from "react-bootstrap-sweetalert"
import ModalReactivateFetcher from "./ModalReactivateFetcher"

const StudentTable = ({
  data,
  header,
  handleExpand,
  dataExpand,
  isLoading,
  isExpandLoading,
  setDataExpand,
  currentPage,
  setCurrentPage,
  fetcherModal,
  setFetcherModal,
  isConfirmDeleteShow,
  setIsConfirmDeleteShow,
  handleDelete
}) => {
  const [dataPerPage, setDataPerPage] = useState(10)
  const [modal, setModal] = useState(false)
  const [Student, setStudent] = useState({ id: null, va: null, name: null })
  const [confirm_alert, setconfirm_alert] = useState(false)

  const toggleModal = (data = null) => {
    if (!modal) {
      setStudent({ id: data.id, va: data.va, name: data.namaSiswa })
      setconfirm_alert(true)
    } else {
      setStudent({ id: null, va: null, name: null })
      setModal(false)
    }
  }

  const indexOfLastData = currentPage * dataPerPage
  const indexOfFirstData = indexOfLastData - dataPerPage
  const currentData = data.slice(indexOfFirstData, indexOfLastData)

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const SFBadge = ({ familly_id, fetcher }) => {
    let badge = "info"
    let caption = "Active"
    if (familly_id !== null) {
      if (fetcher.length === 0) {
        badge = "warning"
        caption = "Email Sent"
      } else {
        badge = "success"
        caption = "Active"
      }
    } else {
      badge = "danger"
      caption = "Unregistered"
    }
    return <Badge className={`bg-${badge}`}>{caption}</Badge>
  }

  SFBadge.propTypes = {
    familly_id: PropTypes.number.isRequired,
    fetcher: PropTypes.string.isRequired,
  }

  return (
    <>
      <div className="table-responsive">
        <Table className="table">
          <thead>
            <tr>
              {header.map((h, i) => (
                <th key={`head-${i}`}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={5}>
                  <LoadingSpinner />
                </td>
              </tr>
            ) : (
              currentData.map(e => {
                return (
                  <React.Fragment key={e.id}>
                    <tr>
                      <td>{e?.va || "N/A"}</td>
                      <td>{e?.namaSiswa || "N/A"}</td>
                      <td>{e?.grade || "N/A"}</td>
                      <td>{e?.kelas || "N/A"}</td>
                      <td>
                        <SFBadge familly_id={e.family_id} fetcher={e.fetcher} />
                      </td>
                      <td className="d-flex">
                        <Button
                          onClick={() => handleExpand(e.id)}
                          color="secondary"
                          className="btn btn-sm"
                        >
                          <i
                            className={`bx bx-chevron-${
                              e.isExpand ? "down" : "right"
                            }`}
                          />
                        </Button>
                        {e.isExpand ? (
                          <Button
                            onClick={() => toggleModal(e)}
                            color="danger"
                            className="btn btn-sm mx-1"
                          >
                            Edit
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    {e.isExpand ? (
                      isExpandLoading ? (
                        <tr>
                          <td colSpan={5}>
                            <LoadingSpinner />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <StudentDetail
                              data={e}
                              dataExpand={dataExpand}
                              setDataExpand={setDataExpand}
                              handleExpand={handleExpand}
                              toggleInactiveDailyFetcherModal={()=> setFetcherModal(!fetcherModal)}
                            />
                          </td>
                        </tr>
                      )
                    ) : null}
                  </React.Fragment>
                )
              })
            )}
          </tbody>
        </Table>
        {/* <p className='text-muted'>Total data : {data.length}</p> */}
      </div>
      <Pagination
        setDataPerPage={setDataPerPage}
        dataPerPage={dataPerPage}
        totalData={data.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      <ModalEditFamily
        modalShow={modal}
        toggleModal={toggleModal}
        Student={Student}
        handleExpand={handleExpand}
      />
      <ModalReactivateFetcher
        modalShow={fetcherModal}
        toggleModal={() => setFetcherModal(!fetcherModal)}
        dataExpand={dataExpand}
        isConfirmDeleteShow={isConfirmDeleteShow}
        setIsConfirmDeleteShow={setIsConfirmDeleteShow}
        handleDelete={handleDelete}
      />
      {confirm_alert ? (
        <SweetAlert
          title="Warning!"
          info
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setModal(true)
            setconfirm_alert(false)
          }}
          onCancel={() => {
            setStudent({ id: null, va: null, name: null })
            setconfirm_alert(false)
          }}
        >
          Are you sure want to edit this student data
        </SweetAlert>
      ) : null}
    </>
  )
}

StudentTable.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  handleExpand: PropTypes.func.isRequired,
  dataExpand: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExpandLoading: PropTypes.bool.isRequired,
  setDataExpand: PropTypes.any,
  currentPage: PropTypes.any,
  setCurrentPage: PropTypes.any,
  fetcherModal: PropTypes.any,
  setFetcherModal: PropTypes.any,
  isConfirmDeleteShow: PropTypes.any,
  setIsConfirmDeleteShow: PropTypes.any,
  handleDelete: PropTypes.any
}

export default StudentTable
