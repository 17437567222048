import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ReactSelect from 'react-select';

function ModalEditSuperFetcher(props) {
    const [showAdd, setShowAdd] = useState(false);
    const [optFetcher, setOptFetcher] = useState([]);

    const formAddFetcher = (
        <>
            <div className='form-group py-1'>
                <label className='form-label'>Fullname</label>
                <input type="text" name="full_name" className='form-control' placeholder='Enter Fullname' onChange={(e) => props.handleChange(e)} />
            </div>
            <div className='form-group py-1'>
                <label className='form-label'>Email</label>
                <input type="email" name="email" className='form-control' placeholder='Enter Email' onChange={(e) => props.handleChange(e)} />
            </div>
            <div className='form-group py-1'>
                <label className='form-label'>Phone</label>
                <input type="text" name="phone" className='form-control' placeholder='Enter Phone' onChange={(e) => props.handleChange(e)} />
            </div>
            <div className='form-group py-1'>
                <label className='form-label'>Relation</label>
                <input type="text" name="relation" className='form-control' placeholder='Enter Relation' onChange={(e) => props.handleChange(e)} />
            </div>
        </>
    )

    const btnSubmit = (
        <Button color="primary" className='btn-sm m-1' onClick={() => props.handleSubmit()}>Submit</Button>
    )

    useEffect(() => {
        const { data } = props;
        const options = [];
        data.length > 0 && data.forEach(item => {
            options.push({
                value: item.id,
                label: item.full_name
            })
        })
        setOptFetcher(options)
    }, [props])

    return (
        <div>
            <Modal
                size="md"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
                scrollable={true}
            >
                <ModalHeader toggle={props.toggleModal}>Edit Super Fetcher</ModalHeader>
                <ModalBody>
                    <Row>
                        {props.length <= 1 ? 
                            <Col>
                                {formAddFetcher}
                                <div className='text-right pt-3'>
                                    {btnSubmit}
                                </div>
                            </Col>
                        :
                            <Col>
                                {showAdd ? 
                                    formAddFetcher
                                :
                                    <>
                                        <div className='form-group'>
                                            <label className='form-label'>Name</label>
                                            <ReactSelect
                                                options={optFetcher}
                                                placeholder="Select Fetcher"
                                                onChange={(e) => props.handleChange(e)}
                                            />
                                        </div>
                                        <div className='form-group py-2'>
                                            <label className='form-label'>Email</label>
                                            <input type="email" name="email" className='form-control' placeholder='Enter Email' onChange={(e) => props.handleChange(e)} />
                                        </div>
                                    </>
                                }
                                <div className='text-right pt-3'>
                                    {!showAdd ? 
                                        <Button color="secondary" className='btn-sm m-1' onClick={() => setShowAdd(true)}>Add Super Fetcher</Button>
                                    : 
                                        <Button color="success" className='btn-sm m-1' onClick={() => setShowAdd(false)}>Edit Super Fetcher</Button>
                                    }
                                    {btnSubmit}
                                </div>
                            </Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
} 

ModalEditSuperFetcher.propTypes = {
    modalShow: PropTypes.any,
    toggleModal: PropTypes.any,
    data: PropTypes.any,
    length: PropTypes.any,
    body: PropTypes.any,
    handleChange: PropTypes.any,
    handleSubmit: PropTypes.any
};

export default withTranslation()(ModalEditSuperFetcher);