import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next";
import { Guru, Siswa } from "./Component/Delegasi";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Delegasi = props => {
    const [modal, setmodal] = useState(false);
    const [activeTab, setactiveTab] = useState("1");

    const toggle = tab => {
        if (activeTab !== tab) {
          setactiveTab(tab);
        }
      };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Tzu Chi</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Pickup")}
                        breadcrumbItem={props.t("Delegation")}
                    />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">Delegation</CardTitle>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                active: activeTab === "1",
                                                })}
                                                onClick={() => {
                                                toggle("1");
                                                }}
                                            >
                                                Teacher
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                active: activeTab === "2",
                                                })}
                                                onClick={() => {
                                                toggle("2");
                                                }}
                                            >
                                                Student
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                                        <TabPane tabId="1">  
                                            <Guru />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Siswa />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

Delegasi.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Delegasi);
