import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "helpers/api_helper";
import imageDashboard from '../../assets/images/dashboard.svg';
import SweetAlert from "react-bootstrap-sweetalert";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";


const Dashboard = props => {
  const [dataSiswa, setDataSiswa] = useState({masuk: 0, diJemput: 0, belumDiJemput: 0, expired: 0});
  const [jumlahSiswa,setJumlahSiswa] = useState(0);
  const [error_alert, seterror_alert] = useState(false);

  const getData = async ()=> {
    try {
      let responsePickup = await get("/student/all/pickup");
      const data = responsePickup.data;
      const pickup = data.filter(x => x.pickup !== null);
      const masuk = pickup.filter(x => x.pickup.status === 'accepted').length;
      const diJemput = pickup.filter(x => x.pickup.status === 'done').length;
      const expired = pickup.filter(x => x.pickup.status === 'canceled').length;

  
      const belumDiJemput = data.filter(x => x.pickup === null).length;
      setDataSiswa({...dataSiswa, masuk: masuk, diJemput: diJemput, belumDiJemput: belumDiJemput, expired: expired});
      setJumlahSiswa(data.length)
    } catch (error) {
      seterror_alert(true);
    }
  }

  useEffect (() => {
    getData();
  }, []);

  useEffect(() => {
    const fetchData = setInterval(getData,10000)
    return () => clearInterval(fetchData)
  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pickup")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col className="col-12">
              <Card className="bg-primary bg-opacity-25 px-2 py-welcome" style={{position: 'relative'}}>
                <CardBody>
                  <div>
                    <CardTitle className="h4 text-primary">Welcome!</CardTitle>
                    <p className="text-primary mb-0">Tzu Chi School Pickup System Management Dashboard</p>
                  </div>
                  <div style={{position: 'absolute', bottom: 0, right: 50}}>
                    <img src={imageDashboard} className="w-welcome" />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card style={{minHeight: 270}}>
                <CardBody>
                  <CardTitle className="h4">Pickup Status</CardTitle>
                  <p className="card-title-desc">Total Student : {jumlahSiswa}</p>
                  <Row>
                    <Col md="3">
                      <Link to={`/pickup/penjemputan?status=not-requested`}>
                        <div className="bg-secondary bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                          <div className="d-flex flex-row justify-content-center align-item-center text-secondary fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                            {dataSiswa.belumDiJemput}
                          </div>
                        </div>
                      </Link>
                      <div className="text-center my-3">Not Requested Yet</div>
                    </Col>

                    <Col md="3">
                      <Link to={`/pickup/penjemputan?status=waiting-to-be-released`}>
                        <div className="bg-info bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                          <div className="d-flex flex-row justify-content-center align-item-center text-primary fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                            {dataSiswa.masuk}
                          </div>
                        </div>
                      </Link>
                      <div className="text-center my-3">Waiting To Be Released</div>
                    </Col>

                    <Col md="3">
                      <Link to={`/pickup/penjemputan?status=finished`}>
                        <div className="bg-success bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                          <div className="d-flex flex-row justify-content-center align-item-center text-success fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                            {dataSiswa.diJemput}
                          </div>
                        </div>
                      </Link>
                      <div className="text-center my-3">Finished Pickup</div>
                    </Col>
                    <Col md="3">
                    <Link to={`/pickup/penjemputan?status=qr-code-expired`}>
                      <div className="bg-danger bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                        <div className="d-flex flex-row justify-content-center align-item-center text-danger fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                          {dataSiswa.expired}
                        </div>
                      </div>
                    </Link>
                    <div className="text-center my-3">QR Code Expired</div>
                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* <Col className="col-3">
              <Card style={{minHeight: 270}}>
                <CardBody>
                  <CardTitle className="h4">QR Code Expired</CardTitle>
                  <p className="card-title-desc"></p>
                  <Row>
                    <Col md="12">
                      <Link to={`/pickup/penjemputan?status=canceled`}>
                        <div className="bg-danger bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                          <div className="d-flex flex-row justify-content-center align-item-center text-danger fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                            {dataSiswa.expired}
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

        </Container>
      </div>

      { error_alert ?
          <SweetAlert
              title="Oppss!"
              confirmBtnText="Yes"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  seterror_alert(false)
              }}
          >
              Cannot retrieve data from server
          </SweetAlert>
      : null }
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
