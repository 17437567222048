import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import { get } from "helpers/api_helper";
import { API_URL } from "helpers/url_helper"
import user1 from "../../assets/images/users/avatar-blank.png"
import moment from "moment";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = props => {
  const [avatarErr, setAvatarErr] = useState(false);
  const [dataUser, setDataUser] = useState(null);

  const getDataUser = async () => {
    let response = await get(`user/token/data`);
    let data = response.data[0];
    setDataUser(data);
    console.log(data);
  }

  useEffect(() => {
    getDataUser();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profile" breadcrumbItem="Profile" />

          <Row className="justify-content-center">
            <Col lg="12">
              <Card>
                <CardBody>
                  { dataUser !== null ? 
                    <div>
                      <div className="text-center">
                        { dataUser !== null ?
                          <img
                            src={avatarErr ? user1 : `${API_URL + '/' + dataUser.photo_profile}`}
                            onError={()=> setAvatarErr(true)}
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        : null } 
                        <h5 className="mt-3">{dataUser.full_name}</h5>
                        <table className="table w-50 m-auto table-striped">
                          <tr>
                            <td className="fw-bold py-1">Address</td>
                            <td className="text-center">{dataUser.address}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold py-1">Phone</td>
                            <td className="text-center">{dataUser.phone}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold py-1">Birth Date</td>
                            <td className="text-center">
                              {moment(dataUser.birthdate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-bold py-1">Relationship</td>
                            <td className="text-center">{dataUser.relationship}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  : null }
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
