import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  Badge,
  NavItem,
} from "reactstrap";
import { get } from "helpers/api_helper";
import { API_URL } from "helpers/url_helper";
import user1 from "../../../../assets/images/users/avatar-blank.png";

//i18n
import { withTranslation } from "react-i18next";

const ModalDetail = (props) => {
    const [student, setStudent] = useState([]);

    const getDataStudent = async () => {
        let response = await get(`/student/all/data?class_id=${props.idClass}&data=all`);
        let data = response.data;
        setStudent(data);
    }

    useEffect (()=>{
        if(props.idClass !== null) { 
            setStudent([]);
            getDataStudent(); 
        }
    }, [props.modalShow]);
   
    return(
        <div>
            <Modal
                size="lg"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
                scrollable={true}
            >
                <ModalHeader toggle={props.toggleModal}>Detail Class</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Student Name</th>
                                        <th>Va</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { student.length > 0 ? 
                                        student.map((x, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>
                                                        {student.length > 0 ?
                                                            <Avatar photo={x.photo} />
                                                        : null }
                                                    </td>
                                                    <td className="align-middle">{x.name}</td>
                                                    <td className="align-middle">{x.va}</td>
                                                    <td className="align-middle">
                                                        {x.allow_pickup == null ? 
                                                            <Badge className="bg-secondary py-1">Abstain</Badge>
                                                            : 
                                                            <Badge className="bg-success py-1">Present</Badge>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    : 
                                        <tr>
                                            <td colSpan={3} className="text-center">No Student</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-row justify-content-end">
                                <button className="btn btn-sm btn-danger" onClick={props.toggleModal}>Close</button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}

const Avatar = (props) => {
    const [avatarErr, setAvatarErr] = useState(false);
    return(
        <img
            src={avatarErr ? user1 : `${API_URL + `/` + props.photo}` }
            onError={() => setAvatarErr(true)}
            className="avatar-md img-thumbnail border border-dark"
            style={{objectFit: 'cover'}}
        />
    )
}

Avatar.propTypes = {
    photo: PropTypes.any
}

ModalDetail.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
    idClass: PropTypes.any
};

export default withTranslation()(ModalDetail);